.about-window {
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  max-width: 800px;
  transition: box-shadow 0.3s ease;
}

.about-window:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}

.window-bar {
  display: flex;
  flex-direction: row;
  background: #b4b4b4;
  opacity: 0.9;
  padding: 10px;
  border-radius: 7px 7px 0px 0px;
}

.close-button {
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  background-color: #ff5f57;
  /* macOS close button color */
}

.dot {
  cursor: default;
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #f9f9f9;
  border-radius: 50%;
  margin: 2px 6px 2px 0;
  padding: 1px;
}

.dot.red {
  background: #ff6057;
  cursor: pointer;
}

.dot.amber {
  background: #ffbd2e;
  cursor: pointer;
}

.dot.green {
  background: #27c93f;
}

.about-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.about-content p {
  padding: 12px;
  text-align: start;
}
