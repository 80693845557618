.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  height: 75vh;
}

.home-top-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5vw;
}

.home-text {
  margin: 20px;
}

#self-pic {
  margin: 24px;
  width: 20vw;
  height: auto;
  border-radius: 50%;
}

.home-icons {
  display: flex;
}

.social-button {
  margin: 0.5rem;
  color: #3f3f3f;
  font-size: 2rem;
  transition:
    transform 0.15s ease,
    margin 0.15s ease;
}

.social-button:hover {
  transform: scale(1.25);
  margin-left: 0.65rem;
  margin-right: 0.65rem;
}

.social-button:not(:hover) {
  transition:
    transform 0.15s ease,
    margin 0.15s ease;
}

.linkedin {
  color: #0077b5;
}

.github {
  color: #181717;
}

.strava {
  color: #fc4c02;
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  .home-top-content {
    flex-direction: column;
  }

  #self-pic {
    width: 50vw;
  }
}
